@import url("../../../Colours.css");

.footerContainer{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.header{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.links{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}

.navItem{
    margin:20px;
}

.navIcon{
    color:var(--notQuiteBlack);
}

@media screen and (max-width: 700px) {
    .header{
        font-size: 5.5vw;
    }
    .navItem{
        margin:3vw;
    }
}