.projectSection{
    padding-bottom: 20px;
}

.projectItem{
    --padding-top-and-bottom: 50px;
    padding-top: var(--padding-top-and-bottom);
    padding-bottom: var(--padding-top-and-bottom);
}

.projectItem:nth-of-type(1n){
   background-color:#FDE7EF
}
.projectItem:nth-of-type(2n){
    background-color:#E0FAF6;

}
.projectItem:nth-of-type(3n){
   background-color:#D8FCCF;
}
.projectItem:nth-of-type(4n){
   background-color:#F1D6ED;
}

@media screen and (max-width: 600px) {
    .projectItem:nth-child(1n){
        --padding-top-and-bottom: 2vw;
        background-color: transparent;
    }
    .imageWrapper{
        padding: 25px;
        margin:5px;
    }

    .projectItem:nth-child(1n) .imageWrapper{
        margin-right: calc(var(--padding-on-main-container)*-1);
        border-radius: 20px 0px 0px 20px;
        background-color:#FDE7EF;
    }

    .projectItem:nth-child(2n) .imageWrapper{
        margin-right: 5px;
        margin-left: calc(var(--padding-on-main-container)*-1);
        border-radius: 0px 20px 20px 0px;
        background-color:#E0FAF6;
    }
    .projectItem:nth-child(3n) .imageWrapper{
        background-color:#D8FCCF;
    }
    .projectItem:nth-child(4n +1) .imageWrapper{
        background-color:#F1D6ED;
    }
 
}