/*
TODO: Check this max-width later on
*/
.infoCardSection{
    max-width: 1400px;
    margin:auto;
}
.textWrapper h3{
    font-weight: 600;
    font-size: 40px;
}
.projectLink{
    font-weight: 300;
    font-size: 20px;
    color:black;
}

.projectImg{
    height: 450px;
}

.projectContainerRight .projectImg{
    margin-left:20px;
}

.projectContainerLeft .projectImg{
    margin-right:20px;
}

.projectContainerRight{
    display: flex;
    justify-content: space-between;
}

.projectContainerLeft{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.projectContainerLeft .textWrapper{
    text-align: right;
}

.textWrapper{
    max-width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    .textWrapper h3{
        font-size: 30px;
    }
    .textWrapper p{
        font-size: 19px;
    }
}

@media screen and (max-width: 600px) {
    .textWrapper h3{
        font-size: 6vw;
    }
    .textWrapper p{
        font-size: 3.5vw;
    }
    .projectLink{
        font-size: 4.5vw;
    }
    .textWrapper{
        gap:25px;
    }
    .projectImg{
        height: 65vw;
    }
}