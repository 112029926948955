/*Removing the blue highlight when buttons is pressed on mobile.*/
* {
  -webkit-tap-highlight-color: transparent;
}
/*
  TODO: Clean up this file. 
*/
.navbar {
  background: transparent;
  height: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  z-index: 999;
  max-width: 2800px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 900px;
  margin-right: 300px;
}

.fa-typo3 {
  font-size: 1.5rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 25px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: end;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #242222;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 25px;
  height: 100%;
  transition: all 0.5s ease-out;
  text-transform: uppercase;
  font-weight: 300;
}

.nav-links:hover {
  text-decoration: underline;
  font-size:1.25vw;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.nav-item-icon{
  display: flex;
  flex-direction: column;
  align-items: center; 
  height: 10%;
}

.imageIcon{
  height: 5vh;
  width: 5vh;
  opacity: 1;
}

@media screen and (max-width: 1400px){
  .nav-links {
    font-size: 1.7vw;
  }
}

@media screen and (max-width: 960px) {
  /*Top bar active. Non active further up*/
  .navbar{
    position:sticky;
    height: 80px;
  }
  .navbar.active {
    background: #242222;
    opacity: 0.9;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
  }
  

  /*Menu with links*/
  .nav-menu.active {
    background: #242222;
    left: 0;
    transition: all 0.5s ease;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
    padding-bottom: 15%;
  }

  .nav-item-icon{
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    gap:30px;
  }

  .nav-links-icon {
    color:var(--rubyRed);

  }

  .nav-links {
    text-align: center;
    color: white;
    font-size:3vh;
    width: 100%;
    display:table;
  }

  .nav-links:hover {
    text-decoration: underline;
    border-radius: 0;
    font-size: 3.5vh;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    transition: all 0.3s ease-out;
  }
}