
.container{
    height: calc(100vh - 80px);
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 2800px;
    overflow: hidden;
}

.heading{
    grid-column: 2;
    grid-row:1;
    align-self: flex-end;
}
.heading h2{
    font-size: 60px;
    font-weight: 500;
    letter-spacing: .2rem;
}
.heading h2:nth-of-type(2){
    margin-left: 60px;
}
.text{
    grid-column: 2;
    grid-row:2;
}
.text p{
    font-size: 29px;
}
.profilePic{
    /*. The order of the values is row-start/column-start/row-end/column-end.*/
    grid-column: 3;
    grid-row:1/3;
    align-self: center;
    justify-self: flex-end;
    width: 55vh;
}

@media screen and (max-width: 960px) {
    .text p{
        font-size: 29px;
    }
    .container{
        display: grid;
        grid-template-rows: 1fr 0.3fr 2fr;
        grid-template-columns: 1fr;
        gap:2px;
    }
    .heading{
        grid-row:1;
        grid-column: 1;
        padding-left: 20px;
    }
    .heading h2{
        font-size: 5vw;
    }
    .text{
        padding-left: 20px;
        grid-row:2;
        grid-column: 1;
        display: flex;
        align-items: flex-end;
        text-align: left;
        padding-bottom: 10px;
    }
    .profilePic{
        max-width: 100%;
        grid-row:3;
        grid-column: 1;
        align-self: flex-end; 
        justify-self:center;
    }
}



@media screen and (max-width: 600px) {
    .text p{
        font-size: 5vw;
    }
    .heading h2{
        font-size: 8vw;
    }
    .profilePic{
        object-fit:cover;
        height:60vh;
        width: 100%;
    }

}